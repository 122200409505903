<!--分销员主页-->
<template>
    <div>
        <!--信息总览-->
        <div class="fans-wrapper">
            <div class="fans-info-wrapper">
                <p>{{fansCount}}</p>
                <p>我的粉丝</p>
            </div>
            <div class="fans-info-wrapper">
                <p>{{memberCount}}</p>
                <p>二级分销</p>
            </div>
            <div class="fans-info-wrapper">
                <p>{{orderCount}}</p>
                <p>分销次数</p>
            </div>
        </div>
        <!--荣耀墙-->
        <div class="honor-wall-wrapper">
            <grid-title icon="https://seetop-1257860468.file.myqcloud.com/html/distributor/icon_20200922.png" title="我的成就"></grid-title>
            <div class="honor-info-wrapper">
                <p>您已完成直推<span>{{levelOrder1}}</span>次</p>
                <p>二级推广<span>{{levelOrder2}}</span>次</p>
                <p>分销总金额<span>￥{{computedMoney}}</span></p>
            </div>
        </div>
        <!--切换-->
        <tab-switch :tab="['我的二级','我的粉丝']" active-item="我的二级" v-on:myClick="tab"></tab-switch>
        <!--列表-->
        <div>
            <div class="list-wrapper">
                <van-list
                        v-model="listLoading"
                        :finished="listLoadingFinished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <div v-show="currentTab == '我的二级'">
                        <div class="item-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                            <div>
                                <div class="item-left-top-wrapper">
                                    <p>{{item.weixinNick}}</p>
                                    <p class="item-sale-info">成交：<span class="item-sale-number">{{item.countOrder}}</span>单</p>
                                </div>
                                <p class="">
                                    <span>{{item.create_time}}</span> 加入您的团队
                                </p>
                            </div>
                            <div class="item-right-wrapper">
                                <p>+<span>{{moneyFen2Yuan(item.sumMoney)}}元</span></p>
                                <p>贡献奖励</p>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentTab == '我的粉丝'">
                        <div class="item-fans-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                            <div class="item-left-top-wrapper">
                                <p>{{item.weixinNick}}</p>
                            </div>
                            <p>
                                <span>{{item.create_time}}</span> 成为您的粉丝
                            </p>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import GridTitle from '../../components/user/GridTitle'
  import TabSwitch from '../../components/common/TabSwitch'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import { moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'index',
    mixins: [globalConfig, listLoading],
    components: { FloatNav, TabSwitch, GridTitle },
    data () {
      return {
        money: 0,
        memberCount: 0,
        fansCount: 0,
        orderCount: 0,
        levelOrder1: 0,
        levelOrder2: 0,
        currentTab: '我的二级'
      }
    },
    methods: {
      // 初始化
      init: function () {
        this.axios.post(this.apiUrl + 'mall/distributor/fansInit').then((response) => {
          const data = response.data
          if (data.status !== 1) {
            this.$router.push('/user')
          }
          this.money = data.money
          this.memberCount = data.memberCount
          this.fansCount = data.fansCount
          this.orderCount = data.orderCount
          this.levelOrder1 = data.levelOrder[1]
          this.levelOrder2 = data.levelOrder[2]
        })
      },
      tab: function (name) {
        console.log(name)
        if (name === '我的二级') {
          this.listLoadingInit(this.apiUrl + 'mall/distributor/getOwnDistributorList', 'list', 1)
        } else if (name === '我的粉丝') {
          this.listLoadingInit(this.apiUrl + 'mall/distributor/getFansList', 'list', 1)
        }
        this.currentTab = name
      },
      moneyFen2Yuan: function (money) {
        return moneyFen2Yuan(money)
      }
    },
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.money)
      }
    },
    mounted () {
      this.init()
      this.listLoadingInit(this.apiUrl + 'mall/distributor/getOwnDistributorList', 'list', 1)
    }
  }
</script>

<style scoped>
    *{
        box-sizing: border-box;
    }
    .fans-wrapper{
        width: 100vw;
        display: flex;
        justify-content: space-between;
        padding: 4vw 10vw;
    }
    .fans-wrapper p:nth-of-type(1){
        font-size: 4.2vw;
        font-weight: 600;
        color: #444;
    }
    .fans-wrapper p:nth-of-type(2){
        font-size: 3.8vw;
        color: #999;
    }
    .fans-info-wrapper p{
        text-align: center;
        width: 25vw;
        padding: 0.5vw 0;
    }
    .honor-wall-wrapper{
        width: 92vw;
        margin: 2vw 4vw 4vw 4vw;
        padding: 4vw 3vw;
        border-radius: 2vw;
        box-shadow: 0 0 8px #cfcfcf;
    }
    .honor-info-wrapper{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/banner_20200922.png") no-repeat;
        background-size: 100% 100%;
        width: 84vw;
        height: 32vw;
        margin: 3vw auto 1vw auto;
        padding: 7vw 8vw;
    }
    .honor-info-wrapper p{
        width: 84vw;
        height: 7.2vw;
        line-height: 7.2vw;
        font-size: 4.2vw;
        font-weight: 600;
        color: #b54317;
    }
    .honor-info-wrapper span{
        margin: 0 2vw;
    }
    .list-wrapper{
        padding: 3vw 7.8vw;
        font-size: 3.2vw;
        color: #999;
    }
    .item-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.3rem 0;
        border-bottom: 0.01rem solid #eeeeee;
    }
    .item-fans-wrapper {
        padding: 0.7rem 0;
        border-bottom: 0.01rem solid #eeeeee;
    }
    .item-left-top-wrapper{
        display: flex;
        align-items: center;
        padding: 1vw 0;
    }
    .item-left-top-wrapper p:nth-of-type(1){
        font-size: 1rem;
        font-weight: 900;
        color: #444;
        margin-right: 4vw;
    }
    .item-right-wrapper p{
        text-align: center;
    }
    .item-right-wrapper p:nth-of-type(1){
        color: #444;
        font-weight: 700;
        font-size: 4.8vw;
    }
    .item-sale-info {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .item-sale-number {
        color: #b54317;
        margin-right: 1vw;
        font-size: 1.9rem;
        font-weight: 700
    }
</style>
